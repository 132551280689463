$font-size: 12pt;
$line-height: $font-size * 1.428;

$monospace-fonts: 'Anonymous Pro', 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
                  monospace;
$sans-serif-fonts: 'Raleway', sans-serif;
$serif-fonts: 'Lora', serif;

$code-foreground: #337ab7;
$code-background: lighten(#337ab7, 45%);

@import "bigfoot-default";
@import "blockquotes";
@import "code-block";
@import "keys";
@import "lightbox";
@import "navbar";
@import "octicons";
@import "pygments";

body {
  font-family: $serif-fonts;
  font-size: $font-size;
  line-height: $line-height;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $sans-serif-fonts;
}

h1 {
  font-size: 24pt;
}

code, kbd, pre, samp {
  font-family: $monospace-fonts;
}

code {
  background-color: $code-background;
  color: $code-foreground;
}

.bigfoot-footnote__button {
  background-color: rgba($code-foreground, $button-standard-opacity);

  &:hover,
  &:focus {
    background-color: rgba($code-foreground, $button-hovered-opacity);
  }
}

.highlight {
  font-family: $monospace-fonts;
}

a.title.external:after {
  @include octicon(' \f07f');

  position: relative;
  top: -6px;
  right: -2px;
}

a.title {
  text-decoration: none;
}

.contact-list {
  display: inline;
  list-type: none;
}

.content {
  margin-bottom: $line-height * 2;
}

.emoji {
  direction: ltr;
  display: inline-block;
  text-indent: -2000px;
  width: 16pt;
}

.dateline {
  font-size: $font-size;
  margin-bottom: $line-height;
}

.footnote {
  margin-left: 1px;
}

.footnotes {
  border-top-color: #eee;
  border-top-style: solid;
  border-top-width: 2px;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
  font-size: $font-size * 0.9;

  :target {
    background: yellow;
  }
}

.row h1:first-of-type {
  margin-top: 0;
}

.metadata {
  font-style: italic;
  margin-bottom: 10px;
}

.navbar {
  font-family: $sans-serif-fonts;
}

.youtube-embed {
  text-align: center;
}
