$octicons-font-path: "/fonts/" !default;

@mixin octicon($content) {
  content: $content;
  font-family: 'octicons';
  font-size: 16px;
}

@mixin mega-octicon($content) {
  content: $content;
  font-family: 'octicons';
  font-size: 32px;
}

@font-face {
  font-family:"Octicons";
  src:url("#{$octicons-font-path}octicons.eot");
  src:url("#{$octicons-font-path}octicons.eot?#iefix") format("embedded-opentype"),
    url("#{$octicons-font-path}octicons.woff2") format("woff2"),
    url("#{$octicons-font-path}octicons.woff") format("woff"),
    url("#{$octicons-font-path}octicons.ttf") format("truetype"),
    url("#{$octicons-font-path}octicons.svg#octicons") format("svg");
  font-weight:normal;
  font-style:normal;
}


/*

.octicon is optimized for 16px.
.mega-octicon is optimized for 32px but can be used larger.

*/
.octicon, .mega-octicon {
  font: normal normal normal 16px/1 Octicons;
  display: inline-block;
  text-decoration: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  speak: none;
}
.mega-octicon { font-size: 32px; }

.octicon-alert:before { content:"\f02d"; }

.octicon-arrow-down:before { content:"\f03f"; }

.octicon-arrow-left:before { content:"\f040"; }

.octicon-arrow-right:before { content:"\f03e"; }

.octicon-arrow-small-down:before { content:"\f0a0"; }

.octicon-arrow-small-left:before { content:"\f0a1"; }

.octicon-arrow-small-right:before { content:"\f071"; }

.octicon-arrow-small-up:before { content:"\f09f"; }

.octicon-arrow-up:before { content:"\f03d"; }

.octicon-beaker:before { content:"\f0dd"; }

.octicon-bell:before { content:"\f0de"; }

.octicon-bold:before { content:"\f0e2"; }

.octicon-book:before { content:"\f007"; }

.octicon-bookmark:before { content:"\f07b"; }

.octicon-briefcase:before { content:"\f0d3"; }

.octicon-broadcast:before { content:"\f048"; }

.octicon-browser:before { content:"\f0c5"; }

.octicon-bug:before { content:"\f091"; }

.octicon-calendar:before { content:"\f068"; }

.octicon-check:before { content:"\f03a"; }

.octicon-checklist:before { content:"\f076"; }

.octicon-chevron-down:before { content:"\f0a3"; }

.octicon-chevron-left:before { content:"\f0a4"; }

.octicon-chevron-right:before { content:"\f078"; }

.octicon-chevron-up:before { content:"\f0a2"; }

.octicon-circle-slash:before { content:"\f084"; }

.octicon-circuit-board:before { content:"\f0d6"; }

.octicon-clippy:before { content:"\f035"; }

.octicon-clock:before { content:"\f046"; }

.octicon-cloud-download:before { content:"\f00b"; }

.octicon-cloud-upload:before { content:"\f00c"; }

.octicon-code:before { content:"\f05f"; }

.octicon-comment-discussion:before { content:"\f04f"; }

.octicon-comment:before { content:"\f02b"; }

.octicon-credit-card:before { content:"\f045"; }

.octicon-dash:before { content:"\f0ca"; }

.octicon-dashboard:before { content:"\f07d"; }

.octicon-database:before { content:"\f096"; }

.octicon-desktop-download:before { content:"\f0dc"; }

.octicon-device-camera-video:before { content:"\f057"; }

.octicon-device-camera:before { content:"\f056"; }

.octicon-device-desktop:before { content:"\f27c"; }

.octicon-device-mobile:before { content:"\f038"; }

.octicon-diff-added:before { content:"\f06b"; }

.octicon-diff-ignored:before { content:"\f099"; }

.octicon-diff-modified:before { content:"\f06d"; }

.octicon-diff-removed:before { content:"\f06c"; }

.octicon-diff-renamed:before { content:"\f06e"; }

.octicon-diff:before { content:"\f04d"; }

.octicon-ellipses:before { content:"\f101"; }

.octicon-ellipsis:before { content:"\f09a"; }

.octicon-eye:before { content:"\f04e"; }

.octicon-file-binary:before { content:"\f094"; }

.octicon-file-code:before { content:"\f010"; }

.octicon-file-directory:before { content:"\f016"; }

.octicon-file-media:before { content:"\f012"; }

.octicon-file-pdf:before { content:"\f014"; }

.octicon-file-submodule:before { content:"\f017"; }

.octicon-file-symlink-directory:before { content:"\f0b1"; }

.octicon-file-symlink-file:before { content:"\f0b0"; }

.octicon-file-text:before { content:"\f011"; }

.octicon-file-zip:before { content:"\f013"; }

.octicon-file:before { content:"\f102"; }

.octicon-flame:before { content:"\f0d2"; }

.octicon-fold:before { content:"\f0cc"; }

.octicon-gear:before { content:"\f02f"; }

.octicon-gift:before { content:"\f042"; }

.octicon-gist-secret:before { content:"\f08c"; }

.octicon-gist:before { content:"\f00e"; }

.octicon-git-branch:before { content:"\f020"; }

.octicon-git-commit:before { content:"\f01f"; }

.octicon-git-compare:before { content:"\f0ac"; }

.octicon-git-merge:before { content:"\f023"; }

.octicon-git-pull-request:before { content:"\f009"; }

.octicon-globe:before { content:"\f0b6"; }

.octicon-grabber:before { content:"\f103"; }

.octicon-graph:before { content:"\f043"; }

.octicon-heart:before { content:"\2665"; }

.octicon-history:before { content:"\f07e"; }

.octicon-home:before { content:"\f08d"; }

.octicon-horizontal-rule:before { content:"\f070"; }

.octicon-hubot:before { content:"\f09d"; }

.octicon-inbox:before { content:"\f0cf"; }

.octicon-info:before { content:"\f059"; }

.octicon-issue-closed:before { content:"\f028"; }

.octicon-issue-opened:before { content:"\f026"; }

.octicon-issue-reopened:before { content:"\f027"; }

.octicon-italic:before { content:"\f0e4"; }

.octicon-jersey:before { content:"\f019"; }

.octicon-key:before { content:"\f049"; }

.octicon-keyboard:before { content:"\f00d"; }

.octicon-law:before { content:"\f0d8"; }

.octicon-light-bulb:before { content:"\f000"; }

.octicon-link-external:before { content:"\f07f"; }

.octicon-link:before { content:"\f05c"; }

.octicon-list-ordered:before { content:"\f062"; }

.octicon-list-unordered:before { content:"\f061"; }

.octicon-location:before { content:"\f060"; }

.octicon-lock:before { content:"\f06a"; }

.octicon-logo-gist:before { content:"\f0ad"; }

.octicon-logo-github:before { content:"\f092"; }

.octicon-mail-read:before { content:"\f03c"; }

.octicon-mail-reply:before { content:"\f051"; }

.octicon-mail:before { content:"\f03b"; }

.octicon-mark-github:before { content:"\f00a"; }

.octicon-markdown:before { content:"\f0c9"; }

.octicon-megaphone:before { content:"\f077"; }

.octicon-mention:before { content:"\f0be"; }

.octicon-milestone:before { content:"\f075"; }

.octicon-mirror:before { content:"\f024"; }

.octicon-mortar-board:before { content:"\f0d7"; }

.octicon-mute:before { content:"\f080"; }

.octicon-no-newline:before { content:"\f09c"; }

.octicon-octoface:before { content:"\f008"; }

.octicon-organization:before { content:"\f037"; }

.octicon-package:before { content:"\f0c4"; }

.octicon-paintcan:before { content:"\f0d1"; }

.octicon-pencil:before { content:"\f058"; }

.octicon-person:before { content:"\f018"; }

.octicon-pin:before { content:"\f041"; }

.octicon-plug:before { content:"\f0d4"; }

.octicon-plus-small:before { content:"\f104"; }

.octicon-plus:before { content:"\f05d"; }

.octicon-primitive-dot:before { content:"\f052"; }

.octicon-primitive-square:before { content:"\f053"; }

.octicon-pulse:before { content:"\f085"; }

.octicon-question:before { content:"\f02c"; }

.octicon-quote:before { content:"\f063"; }

.octicon-radio-tower:before { content:"\f030"; }

.octicon-reply:before { content:"\f105"; }

.octicon-repo-clone:before { content:"\f04c"; }

.octicon-repo-force-push:before { content:"\f04a"; }

.octicon-repo-forked:before { content:"\f002"; }

.octicon-repo-pull:before { content:"\f006"; }

.octicon-repo-push:before { content:"\f005"; }

.octicon-repo:before { content:"\f001"; }

.octicon-rocket:before { content:"\f033"; }

.octicon-rss:before { content:"\f034"; }

.octicon-ruby:before { content:"\f047"; }

.octicon-search:before { content:"\f02e"; }

.octicon-server:before { content:"\f097"; }

.octicon-settings:before { content:"\f07c"; }

.octicon-shield:before { content:"\f0e1"; }

.octicon-sign-in:before { content:"\f036"; }

.octicon-sign-out:before { content:"\f032"; }

.octicon-smiley:before { content:"\f0e7"; }

.octicon-squirrel:before { content:"\f0b2"; }

.octicon-star:before { content:"\f02a"; }

.octicon-stop:before { content:"\f08f"; }

.octicon-sync:before { content:"\f087"; }

.octicon-tag:before { content:"\f015"; }

.octicon-tasklist:before { content:"\f0e5"; }

.octicon-telescope:before { content:"\f088"; }

.octicon-terminal:before { content:"\f0c8"; }

.octicon-text-size:before { content:"\f0e3"; }

.octicon-three-bars:before { content:"\f05e"; }

.octicon-thumbsdown:before { content:"\f0db"; }

.octicon-thumbsup:before { content:"\f0da"; }

.octicon-tools:before { content:"\f031"; }

.octicon-trashcan:before { content:"\f0d0"; }

.octicon-triangle-down:before { content:"\f05b"; }

.octicon-triangle-left:before { content:"\f044"; }

.octicon-triangle-right:before { content:"\f05a"; }

.octicon-triangle-up:before { content:"\f0aa"; }

.octicon-unfold:before { content:"\f039"; }

.octicon-unmute:before { content:"\f0ba"; }

.octicon-unverified:before { content:"\f0e8"; }

.octicon-verified:before { content:"\f0e6"; }

.octicon-versions:before { content:"\f064"; }

.octicon-watch:before { content:"\f0e0"; }

.octicon-x:before { content:"\f081"; }

.octicon-zap:before { content:"\26a1"; }
