.navbar-custom {
  background-color: #003b6f;
  border-color: #00294e;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#0056a2), to(#003b6f));
  background-image: -webkit-linear-gradient(top, #0056a2, 0%, #003b6f, 100%);
  background-image: -moz-linear-gradient(top, #0056a2 0%, #003b6f 100%);
  background-image: linear-gradient(to bottom, #0056a2 0%, #003b6f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0056a2', endColorstr='#ff003b6f', GradientType=0);
}

.navbar-custom .navbar-brand {
  color: #ffffff;
}

.navbar-custom .navbar-brand:hover,
.navbar-custom .navbar-brand:focus {
  color: #fdb813;
  background-color: transparent;
}

.navbar-custom .navbar-text {
  color: #ffffff;
}

.navbar-custom .navbar-nav > li:last-child > a {
  border-right: 1px solid #00294e;
}

.navbar-custom .navbar-nav > li > a {
  color: #ffffff;
  border-left: 1px solid #00294e;
}

.navbar-custom .navbar-nav > li > a:hover,
.navbar-custom .navbar-nav > li > a:focus {
  color: #fdb813;
  background-color: transparent;
}

.navbar-custom .navbar-nav > .active > a,
.navbar-custom .navbar-nav > .active > a:hover,
.navbar-custom .navbar-nav > .active > a:focus {
  color: #fdb813;
  background-color: #00294e;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#00294e), to(#004481));
  background-image: -webkit-linear-gradient(top, #00294e, 0%, #004481, 100%);
  background-image: -moz-linear-gradient(top, #00294e 0%, #004481 100%);
  background-image: linear-gradient(to bottom, #00294e 0%, #004481 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00294e', endColorstr='#ff004481', GradientType=0);
}

.navbar-custom .navbar-nav > .disabled > a,
.navbar-custom .navbar-nav > .disabled > a:hover,
.navbar-custom .navbar-nav > .disabled > a:focus {
  color: #cccccc;
  background-color: transparent;
}

.navbar-custom .navbar-toggle {
  border-color: #dddddd;
}

.navbar-custom .navbar-toggle:hover,
.navbar-custom .navbar-toggle:focus {
  background-color: #dddddd;
}

.navbar-custom .navbar-toggle .icon-bar {
  background-color: #cccccc;
}

.navbar-custom .navbar-collapse,
.navbar-custom .navbar-form {
  border-color: #00284b;
}

.navbar-custom .navbar-nav > .dropdown > a:hover .caret,
.navbar-custom .navbar-nav > .dropdown > a:focus .caret {
  border-top-color: #fdb813;
  border-bottom-color: #fdb813;
}

.navbar-custom .navbar-nav > .open > a,
.navbar-custom .navbar-nav > .open > a:hover,
.navbar-custom .navbar-nav > .open > a:focus {
  background-color: #00294e;
  color: #fdb813;
}

.navbar-custom .navbar-nav > .open > a .caret,
.navbar-custom .navbar-nav > .open > a:hover .caret,
.navbar-custom .navbar-nav > .open > a:focus .caret {
  border-top-color: #fdb813;
  border-bottom-color: #fdb813;
}

.navbar-custom .navbar-nav > .dropdown > a .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}

@media (max-width: 768px) {
  .navbar-custom .navbar-nav .open .dropdown-menu > li > a {
    color: #ffffff;
  }

  .navbar-custom .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-custom .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fdb813;
    background-color: transparent;
  }

  .navbar-custom .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-custom .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-custom .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fdb813;
    background-color: #00294e;
  }

  .navbar-custom .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-custom .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-custom .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
  }
}

.navbar-custom .navbar-link {
  color: #ffffff;
}

.navbar-custom .navbar-link:hover {
  color: #fdb813;
}
