blockquote {
  background: #fff;
  border-left: 15px solid #337ab7;
  border-right: 2px solid #337ab7;
  box-shadow: 2px 2px 15px #ccc;
  color: #666;
  display: block;
  font-family: $serif-fonts;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0 0 20px;
  padding: 15px 20px 15px 45px;
  position: relative;
}

blockquote::before {
  color: #999;
  content: '\201c'; // Unicode for left double-quote
  font-family: Georgia, serif;
  font-size: 60px;
  font-weight: bold;
  left: 10px;
  position: absolute;
  top: 30px;
}

blockquote::after {
  content: '';
}
